import { FC, Fragment } from 'react'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

import useMigrateKBLinks from './useMigrateKBLinks'
import useMigrateUserStatus from './useMigrateUsersStatus'
import useSyncKBRootParents from './useSyncKBRootParents'

interface Props {
  open: boolean
  handleClose: () => void
  syncingCourseTracking: boolean
  syncCourseTracking: () => void
}

const OneOffJobsDialog: FC<Props> = (props) => {
  const { handleClose, open, syncCourseTracking, syncingCourseTracking } = props
  const { syncUserStatus, syncingUserStatus } = useMigrateUserStatus()
  const { syncKBRootParents, syncingKBRootParents } = useSyncKBRootParents()
  const { migrateKBLinks, migratingKBLinks } = useMigrateKBLinks()

  return (
    <Fragment>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle className="flex gap-2 items-center">
          One-Off Jobs
          <DialogContentText>
            Sometimes you just need a button to do something once.
          </DialogContentText>
        </DialogTitle>
        <DialogContent className="flex flex-col items-center gap-2 w-full">
          <Button
            className="w-72"
            variant="outlined"
            disabled={syncingCourseTracking}
            onClick={syncCourseTracking}
          >
            {`${syncingCourseTracking ? 'Syncing' : 'Migrate'}`} Course
            Trackings
          </Button>{' '}
          <Button
            className="w-72"
            variant="outlined"
            disabled={syncingUserStatus}
            onClick={syncUserStatus}
          >
            {`${syncingUserStatus ? 'Syncing' : 'Migrate'}`} User Status
          </Button>
          <Button
            className="w-72"
            variant="outlined"
            disabled={syncingKBRootParents}
            onClick={syncKBRootParents}
          >
            {`${syncingKBRootParents ? 'Syncing' : 'Sync'}`} KB Root Parents
          </Button>
          <Button
            className="w-72"
            variant="outlined"
            disabled={migratingKBLinks}
            onClick={migrateKBLinks}
          >
            {`${migratingKBLinks ? 'Migrating' : 'Migrate'}`} KB Links
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

export default OneOffJobsDialog
