import { useState } from 'react'

import { migrateKBLinks } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

const MUTATION = gql`
  mutation migrateKBLinks {
    migrate: migrateActiveKBLinks
  }
`

const useMigrateKBLinks = () => {
  const [migratingKBLinks, setMigratingKBLinks] = useState(false)

  const [migrate] = useMutation<migrateKBLinks>(MUTATION, {
    onCompleted: (_items) => {
      toast.success('Migration Complete', {
        duration: 2000,
        className: 'flex-column',
      })
    },
    onError: (error) => {
      toast.error(error.message, {
        duration: 10000,
        className: 'flex-column',
      })
    },
  })

  const migrateKBLinks = async () => {
    setMigratingKBLinks(true)

    migrate().finally(() => {
      setMigratingKBLinks(false)
    })
  }

  return {
    migratingKBLinks,
    migrateKBLinks,
  }
}

export default useMigrateKBLinks
