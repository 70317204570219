import { useState } from 'react'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

const MUTATION = gql`
  mutation syncKBRootParents {
    sync: syncKnowledgeBaseRootParentIds
  }
`

const useSyncKBRootParents = () => {
  const [syncingKBRootParents, setSyncingKBRootParents] = useState(false)

  const [sync] = useMutation(MUTATION, {
    onCompleted: (_items) => {
      toast.success('Sync Complete', {
        duration: 2000,
        className: 'flex-column',
      })
    },
    onError: (error) => {
      toast.error(error.message, {
        duration: 10000,
        className: 'flex-column',
      })
    },
  })

  const syncKBRootParents = async () => {
    setSyncingKBRootParents(true)

    sync().finally(() => {
      setSyncingKBRootParents(false)
    })
  }

  return {
    syncingKBRootParents,
    syncKBRootParents,
  }
}

export default useSyncKBRootParents
