import { Metadata } from '@redwoodjs/web'

import PageHeader from 'src/components/PageHeader/PageHeader'
import ClientsCell from 'src/components/SuperAdmin/Client/ClientsCell'

const ClientsPage = () => {
  return (
    <>
      <Metadata title="Client Management" description="Client Management" />
      <PageHeader title="Client Management" />
      <ClientsCell />
    </>
  )
}

export default ClientsPage
