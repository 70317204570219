import { FC } from 'react'

import type { FindClients, FindClientsVariables } from 'types/graphql'

import { Link, routes } from '@redwoodjs/router'
import type { CellSuccessProps, CellFailureProps } from '@redwoodjs/web'

import { default as LoadingSpinner } from 'src/components/Library/Loading'
import Clients from 'src/components/SuperAdmin/Client/Clients'

export const QUERY = gql`
  query FindClients {
    clients {
      id
      createdAt
      updatedAt
      createdByUser {
        id
        name
      }
      updatedByUser {
        id
        name
      }
      name
      status
      membershipCount
    }

    aclForClients: aclForResourceType(input: { resourceType: Client }) {
      id
      resourceId
      resourceType
      principalId
      principalType
      principal {
        __typename
        ... on ACLPrincipalMembership {
          membership {
            id
            user {
              id
              name
            }
          }
        }
        ... on ACLPrincipalMembershipGroup {
          membershipGroup {
            id
            name
          }
        }
      }
    }
  }
`

export const Loading = () => <LoadingSpinner />

export const Empty = () => {
  return (
    <div className="rw-text-center">
      {'No clients yet. '}
      <Link to={routes.newClient()} className="rw-link">
        {'Create one?'}
      </Link>
    </div>
  )
}

export const Failure = ({ error }: CellFailureProps) => (
  <div className="rw-cell-error">{error.message}</div>
)
type Props = CellSuccessProps<FindClients, FindClientsVariables>
export type Client = Props['clients'][0]
export type ClientWithACL = Client & {
  acl: Props['aclForClients']
}
export type SuperAdminRefetch = Props['queryResult']['refetch']
export const Success: FC<Props> = ({
  clients,
  aclForClients,
  queryResult: { refetch },
}) => {
  const clientsWithACL: ClientWithACL[] = clients.map((client) => {
    const acl = aclForClients.filter((acl) => acl.resourceId === client.id)
    return { ...client, acl }
  })

  return <Clients clients={clientsWithACL} refetch={refetch} />
}
