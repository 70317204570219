import { captureException } from '@sentry/browser'
import type { ACLPrincipalType } from 'types/graphql'

export type ACLData = {
  principalType?: ACLPrincipalType
  principal?: unknown
}

export const getACLLabels = (aclData: ACLData[]): string[] => {
  const aclLabels = aclData?.map((acl) => {
    if (acl.principalType === 'MEMBERSHIP') {
      const result: string = acl.principal?.['membership']?.user?.name
      return result || 'Unknown'
    }
    if (acl.principalType === 'MEMBERSHIPGROUP') {
      const result: string = acl.principal?.['membershipGroup']?.name
      return result || 'Unknown'
    }
    if (acl.principalType === null) {
      return 'Allow all'
    }

    captureException(new Error(`Unknown principal type (${acl.principalType})`))
    return 'Unknown'
  })

  return aclLabels.length > 0 ? aclLabels : ['Deny all']
}
