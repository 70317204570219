import { FC } from 'react'

import { Link, navigate, routes } from '@redwoodjs/router'

import Button from 'src/components/Library/Button'
import { SearchField } from 'src/components/Library/SearchField/SearchField'
import { getACLLabels } from 'src/lib/hooks/ACL/helpers'

import { ClientWithACL, SuperAdminRefetch } from '../ClientsCell'

import OneOffJobsDialog from './OneOffJobsDialog'
import useCourseTrackingMutation from './useCourseTrackingMutation'

const MAX_STRING_LENGTH = 150

const truncate = (text: string) => {
  let output = text
  if (text && text.length > MAX_STRING_LENGTH) {
    output = output.substring(0, MAX_STRING_LENGTH) + '...'
  }
  return output
}

const toLocalTime = (datetime: string) =>
  new Date(datetime).toLocaleString(undefined, {
    hour12: true,
  })

interface Props {
  clients: ClientWithACL[]
  refetch: SuperAdminRefetch
}

const ClientsList: FC<Props> = ({ clients }) => {
  const [openOneOffJobs, setOpenOneOffJobs] = React.useState(false)
  const [searchValue, setSearchValue] = React.useState('')
  const handleOneOffJobsClickOpen = () => {
    setOpenOneOffJobs(true)
  }

  const handleOneOffJobsClose = () => {
    setOpenOneOffJobs(false)
  }

  const { syncCourseTracking, syncingCourseTracking } =
    useCourseTrackingMutation({ clients })

  const clientStatus = (status: string) => {
    if (status === 'ACTIVE') {
      return <td className="text-green-500 w-40">Active</td>
    } else if (status === 'PAUSED') {
      return <td className="text-orange-500 w-40">Paused</td>
    } else if (status === 'SUSPENDED') {
      return <td className="text-red-500 w-40">Suspended</td>
    } else if (status === 'INACTIVE') {
      return <td className="text-blue-500 w-40">Inactive</td>
    } else {
      return <td>Unknown</td>
    }
  }

  return (
    <>
      <div className="rw-segment rw-table-wrapper-responsive">
        <div className="flex flex-row flex-wrap items-center justify-between p-4">
          <div className="text-xl block">All Clients</div>
          <div className="my-auto flex gap-4">
            <Button
              variant="outlined"
              onClick={handleOneOffJobsClickOpen}
              className="w-40 text-white bg-orange-500 hover:bg-orange-700 border-0 hover:border-0"
            >
              One-Off Jobs
            </Button>
            <Button
              className="w-40"
              variant="contained"
              onClick={() => navigate(routes.newClient())}
            >
              + New client
            </Button>
          </div>
        </div>
        <div className={'m-2'}>
          <SearchField value={searchValue} onChange={setSearchValue} />
        </div>
        <table className="rw-table">
          <thead>
            <tr>
              <th>Id</th>
              <th>Name</th>
              <th>Created by</th>
              <th>Updated by</th>
              <th>Client Status</th>
              <th>Total Users</th>
              <th>Support Mode</th>
              {/* Edit link */}
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {clients
              .filter(
                (client) =>
                  client.name
                    .toLowerCase()
                    .includes(searchValue.toLowerCase()) ||
                  client.id.toString().includes(searchValue),
              )
              .map((client) => (
                <tr
                  key={client.id}
                  onDoubleClick={() => {
                    navigate(routes.editClient({ id: client.id }))
                  }}
                >
                  <td className="w-14">{client.id}</td>
                  <td className="w-40">{truncate(client.name)}</td>
                  <td className="w-48" title={toLocalTime(client.createdAt)}>
                    <span className="underline decoration-dotted decoration-blue-400">
                      {truncate(client.createdByUser?.name)}
                    </span>
                  </td>
                  <td
                    className="w-48 decoration-dashed"
                    title={toLocalTime(client.updatedAt)}
                  >
                    <span className="underline decoration-dotted decoration-blue-400">
                      {truncate(client.updatedByUser?.name)}
                    </span>
                  </td>
                  {clientStatus(client.status)}
                  <td>{client.membershipCount}</td>
                  <td>{getACLLabels(client.acl).join(' ,')}</td>
                  <td>
                    <nav className="rw-table-actions">
                      <Link
                        to={routes.editClient({ id: client.id })}
                        title={'Edit client ' + client.id}
                        className="rw-button rw-button-small rw-button-blue"
                      >
                        Edit
                      </Link>
                    </nav>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <OneOffJobsDialog
        {...{ syncCourseTracking, syncingCourseTracking }}
        open={openOneOffJobs}
        handleClose={handleOneOffJobsClose}
      />
    </>
  )
}

export default ClientsList
